import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useFetchFormTemplateMutation } from "../../../features/admin/formTemplate/formTemplateApi";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import { toast } from "react-hot-toast";
import {
  useAddFormSetupMutation,
  useDeleteFormSetupMutation,
  useFetchFormSetupMutation,
} from "../../../features/admin/formSetup/formSetupApi";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};
export default function FormSetup() {
  const navigate = useNavigate();
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [tableDataItems, setTableDataItems] = useState(null);

  const [formTemplatesData, setFormTemplatesData] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [templateData, setTemplateData] = useState(null);

  const formTypes = [
    { id: 1, name: "Payment" },
  { id: 2, name: "Warranty" },
  { id: 3, name: "KYC" },
  { id: 4, name: "Login" },
  { id: 5, name: "Registration" },
  { id: 6, name: "Profile" },
  { id: 7, name: "Panel-AddUser" },
  { id: 8, name: "App-AddUser" },
  { id: 9, name: "Enquiry" },
  { id: 10, name: "WheelCampaign" },
  { id: 11, name: "App-Feedback" },
  { id: 12, name: "Report-Qr" },
  ];
  const [selectedFromType, setSelectedFromType] = useState(-1);

  const [selectedFrom, setSelectedFrom] = useState(-1);
  const [formList, setFormList] = useState([]);

  const {
    data: vendorsData,
    isError: vendorsIsError,
    isLoading: vendorsIsLoading,
    error: vendorsError,
  } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const [
    fetchFormTemplates,
    { data: formTemplateData, error: formTemplateError },
  ] = useFetchFormTemplateMutation();

  const [
    deleteFormSetup,
    { data: deleteFormSetupData, error: deleteFormSetupError },
  ] = useDeleteFormSetupMutation();

  const [
    fetchFormUsageData,
    {
      data: FormUsageData,
      isError: FormUsageIsError,
      isLoading: FormUsageIsLoading,
      error: FormUsageError,
    },
  ] = useFetchFormSetupMutation();

  useEffect(() => {
    fetchFormUsageData(sessionStorage.getItem("access_token"));
    fetchFormTemplates(sessionStorage.getItem("access_token"));
  }, []);

  const [
    addFormSetup,
    {
      data: FormSetupData,
      isLoading: FormSetupIsLoading,
      isError: FormSetupIsError,
      error: FormSetupError,
    },
  ] = useAddFormSetupMutation();

  const handleSubmit = () => {
    console.log(selectedFromType, selectedFrom);

    if (selectedFromType != -1 && selectedFrom != -1) {
      let formData = null;
      console.log(formList);
      formList.forEach(function (data) {
        if (data.id == selectedFrom) {
          formData = data;
        }
      });

      if (formData) {
        var body = {
          tenant_id: selectedVendorData.tenant_id,
          tenant_name: selectedVendorData.name,
          industry_id: formData.industry_id,
          industry_name: formData.industry_name,
          form_template_id: formData.id,
          form_template_name: formData.template_name,
          form_template_type: selectedFromType,
          user_type: selectedUser,
        };

        addFormSetup({
          token: sessionStorage.getItem("access_token"),
          body: body,
        });
      }
    } else {
      return;
      // toast.error("Error", { id: "err" });
    }
  };

  useEffect(() => {
    if (FormUsageData && FormUsageData.body) {
      (() => {
        console.log("object", FormUsageData);
        setTableDataItems(FormUsageData.body);
      })();
    }
  }, [FormUsageData]);

  useEffect(() => {
    toast.dismiss();
    if (FormSetupData) {
      console.log("formsetupdata", FormSetupData);
      toast.success("Form Template successfully assigned.", {
        id: "formSetupSuccess",
      });
      // const tableData = [...tableDataItems];
      // tableData.push(FormSetupData.body);
      // setTableDataItems(tableData);
      fetchFormUsageData(sessionStorage.getItem("access_token"));
    }
    if (FormSetupError) {
      console.log("formsetupError", FormSetupError);

      toast.error(FormSetupError.data.message, {
        id: "formSetupError",
      });
    }
  }, [FormSetupData, FormSetupError]);

  useEffect(() => {
    if (formTemplateData) {
      (() => {
        setFormTemplatesData(formTemplateData.body);
      })();
    }
    if (formTemplateError) {
      (() => {
        console.log("errror", formTemplateError);
      })();
    }
  }, [formTemplateData, formTemplateError]);

  useEffect(() => {
    if (deleteFormSetupData) {
      toast.dismiss();
      toast.success("Deleted Successfully");
      fetchFormUsageData(sessionStorage.getItem("access_token"));
      console.log("detele form templates ", deleteFormSetupData);
    }
    if (deleteFormSetupError) {
      console.log("errror", deleteFormSetupError);
    }
  }, [deleteFormSetupData, deleteFormSetupError]);

  useEffect(() => {
    if (vendorsData) {
      setVendorsList(vendorsData.body);
    }
    if (vendorsError) {
      toast.error(vendorsError.error, {
        id: "vendorsError",
      });
    }
  }, [vendorsData, vendorsError]);

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
        setUsersList(data.user_types);
      }
    });
  };

  const handleFormTypeChange = (event) => {
    setSelectedFromType(event.target.value);
    const forms = [];
    formTemplatesData.forEach(function (data) {
      if (data.form_type == event.target.value) {
        forms.push(data);
      }
    });
    setFormList(forms);
  };

  const handleFormChange = (event) => {
    // console.log("form -----> ", event.target.value , selectedFromType , formList);
    setSelectedFrom(event.target.value);
  };

  console.log(formTemplateData);

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDeleteClick(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };

  const handleButtonDeleteClick = (r) => {
    console.log(r);

    deleteFormSetup({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
    });
  };

  const handleChange = useCallback((state) => {
    // setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Tenant Id",
      cell: (row) => row.tenant_id,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.tenant_name,
      sortable: true,
    },
    {
      name: "Industry Name",
      selector: (row) => row.industry_name,
      sortable: true,
    },
    {
      name: "Form Name",
      selector: (row) => row.form_template_name,
      sortable: true,
      width: "400px",
    },
    {
      name: "Form Type",
      selector: (row) => {
        if (row.form_template_type == 1) return <p>Payment</p>;
        else if (row.form_template_type == 2) return <p>Warranty</p>;
        else if (row.form_template_type == 3) return <p>KYC</p>;
        else if (row.form_template_type == 4) return <p>Login</p>;
        else if (row.form_template_type == 5) return <p>Registration</p>;
        else if (row.form_template_type == 6) return <p>Profile</p>;
        else if (row.form_template_type == 7) return <p>Panel-AddUser</p>;
        else if (row.form_template_type == 8) return <p>App-AddUser</p>;
        else if (row.form_template_type == 9) return <p>Enquiry</p>;
        else if (row.form_type == 10) return <p>WheelCampaign</p>;
      },
      sortable: false,
      allowOverflow: false,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            if (formTemplateData) {
              for (let i = 0; i < formTemplateData.body.length; i++) {
                // const element = formTemplateData.body[i];
                if (formTemplateData.body[i].id == r.form_template_id) {
                  setTemplateData(formTemplateData.body[i]);
                  handleOpen();
                }
              }
            }
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    console.log("FormType changed");
  }, [selectedFromType]);

  return (
    <div className="">
      <div className="page-content">
        <div className="card p-4">
          <div>
            {open && (
              <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box
                    className="px-5 py-4"
                    style={{ overflowY: "scroll" }}
                    sx={style}
                  >
                    <div className="frmpop_n">
                    <h2 className="card-title poprts">
                          {templateData.template_name}
                        </h2>
                      <div className="frmds grid grid-cols-2 gap-3">
                       
                        {Object.values(templateData.template).map(
                          (element, idx) => {
                            return element.type === "select" ? (
                              <div className="w-full mt-3" key={idx}>
                                <div className="d-flex mb-2">
                                  <span>{element.label} </span>
                                </div>
                                <select className="" disabled>
                                  <option value={"Select Options"}>
                                    Select Options
                                  </option>

                                  {element.options.map((opt) => (
                                    <option value={opt}>{opt}</option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div className="w-full mt-3" key={idx}>
                                <div className="d-flex  mb-2">
                                  <span>{element.label} </span>
                                </div>
                                <input
                                  className="form-control rounded-lg shadow-sm shadow-black px-2"
                                  type={element.type}
                                  maxLength={element.maxLength}
                                  placeholder={element.label}
                                  required={element.required}
                                />
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            )}
          </div>
          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <h4 className="mb-0 card-title">Welcome to Form Setup</h4>
          </div>
          <div className="card-body p-0">
            <div className="row frmds ">
              <div className="col-md-3 mt-3 ">
                <label>Client * </label>
                <select
                  className="js-example-basic-single w-100"
                  value={selectedVendor}
                  onChange={handleVendorChange}
                >
                  <option value={0}>Select Vendor</option>
                  {vendorsList.map((vendor) => (
                    <option key={vendor.id} value={vendor.company_id}>
                      {vendor.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3 mt-3 ">
                <label>Choose User *</label>
                <select
                  className="js-example-basic-single w-100"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <option value={0}>Select User</option>
                  {usersList &&
                    usersList.map((user, idx) => (
                      <option key={idx} value={user}>
                        {user}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3 mt-3 ">
                <label>Form Type *</label>

                <select
                  className="js-example-basic-single w-100"
                  value={selectedFromType}
                  onChange={handleFormTypeChange}
                >
                  <option value={-1}>Select Form Type</option>
                  {formTypes.map((each) => (
                    <option key={each.id} value={each.id}>
                      {each.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3 mt-3 ">
                <label className="text-capitalize font-weight-bold">
                  Form *
                </label>

                <select
                  className="js-example-basic-single w-100"
                  value={selectedFrom}
                  onChange={handleFormChange}
                >
                  <option value={0}>Select Form</option>
                  {formList.map((form) => (
                    <option key={form.id} value={form.id}>
                      {form.template_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <button
                className="btn btn-outline-primary btnall"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="card p-4 mt-4">
          <div className="row ">
            <div className="col-12 col-xl-12">
              <DataTable
                title=""
                data={tableDataItems ? tableDataItems : []}
                columns={columns}
                //selectableRows
                onSelectedRowsChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
