import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { useFetchAllWarrantyMutation, useUpdateWarrantyByIdMutation } from "../../../features/tenant/warranty/warrantyApi";
import { useFetchWarrantyByIdMutation } from "../../../features/tenant/warranty/warrantyApi";
import ProductModal from "../../../components/Tenant/ProductModal/ProductModal";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";

const WarrantyList = () => {
  const { userType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialRequest = {
    name: "",
    product_code: "",
    product_name: "",
    phone: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const [showImageModal, setShowImageModal] = useState(false);
  useEffect(() => {
    if (
      request.product_code === "" &&
      request.name === "" &&
      request.product_name === "" &&
      request.phone === ""
    ) {
      fetchWarrantyListFunction();
      fetchWarrantyListCsvFunction();
    }
  }, [request]);

  const [warrantyList, setwarrantyList] = useState([]);
  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const acceptWarrantyRequestPermission = true;
  const rejectWarrantyRequestPermission = true;

  const onButtonDownloadClick = (url) => {
    // const filename = activateWarrantyData?.body?.warranty_pdf;
    // fetch(filename).then(
    //   (response) => {
    //     response.blob().then((blob) => {
    //       console.log("blob", blob);
    //       // Creating new object of PDF file
    //       const fileURL = window.URL.createObjectURL(blob);
    //       // console.log("fileurl", fileURL);
    //       // Setting various property values
    //       let alink = document.createElement("a");
    //       alink.href = fileURL;
    //       alink.download = filename;
    //       alink.click();
    //     });
    //   }
    // );

    window.open(url, '_blank');

    // const filename = url.split('/').pop();
    // const tenantName = JSON.parse(sessionStorage.getItem("vendorData")).tenant_name;

    // let alink = document.createElement("a");
    // alink.href = url;

    // console.log("filename", filename);

    // alink.setAttribute('download', `${tenantName}-${filename}`);

    // alink.click();
  };

  const [
    fetchWarrantyList,
    { data: warrantyListData, error: warrantyListError },
  ] = useFetchAllWarrantyMutation();
  const [
    fetchWarrantyListCsv,
    { data: warrantyListCsvData, error: warrantyListCsvError },
  ] = useFetchAllWarrantyMutation();

  const [fetchWarrantyById, { data: warrantyData, error: warrantyError }] =
    useFetchWarrantyByIdMutation();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    const hasViewWarrantyPermission = hasPermission("warranty");

    if (!hasViewWarrantyPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
  }, []);

  useEffect(() => {
    let userTypeId = 0;
    const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));

    // console.log(appUserTypes);
    // if (appUserTypes != undefined) {
    //   for (let index = 0; index < appUserTypes.length; index++) {
    //     const element = appUserTypes[index];
    //     if (
    //       element.user_type.toLocaleLowerCase() === userType.toLocaleLowerCase()
    //     ) {
    //       userTypeId = element.user_type_id;
    //     }
    //   }
    // }
    fetchWarrantyListFunction();
    fetchWarrantyListCsvFunction();
  }, [location.pathname, limit, offset]);

  const fetchWarrantyListFunction = () => {
    fetchWarrantyList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchWarrantyListCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchWarrantyListCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (warrantyListData) {
      console.log("warranty list data ", warrantyListData.body);
      setwarrantyList(warrantyListData.body.warrantyList);
      setMyList(warrantyListData?.body?.warrantyList ? warrantyListData.body.warrantyList : []);
      setTotalRows(warrantyListData?.body?.total ? warrantyListData.body.total : 0);
    }

    if (warrantyListError) {
      console.log("warranty list error", warrantyListError);
    }
  }, [warrantyListData, warrantyListError]);

  const handleShowWarranty = (data) => {
    // console.log(data);
    // setModalData(data);
    fetchWarrantyById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.id,
    });
  };

  const [selectedTab, setSelectedTab] = useState("product");

  useEffect(() => {
    if (warrantyData) {
      console.log("warranty by id data", warrantyData);
      setModalData(warrantyData.body);
      setShowModal(true);
    }

    if (warrantyError) {
      console.log("warranty by id error", warrantyError);
      toast.error("Some error occurred", { id: "warrantyErrorById" });
    }
  }, [warrantyData, warrantyError]);


  const [
    handleWarrantyStatusChange,
    { data: handleWarrantyStatusChangeData, error: handleWarrantyStatusChangeError },
  ] = useUpdateWarrantyByIdMutation();


  const updateWarranty = (status, id) => {

    handleWarrantyStatusChange({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: id,
      body: { status: status },
    });
  }

  useEffect(() => {
    toast.dismiss();
    if (handleWarrantyStatusChangeData && handleWarrantyStatusChangeData?.body) {
      console.log("Warranty Status Updated")
      toast.success("Warranty Status Updated", { id: "SucessWarrantyUpdate" })
      setModalData(null);
      setShowModal(false);
      fetchWarrantyListFunction();

    }

    if (handleWarrantyStatusChangeError) {
      console.log("Something went wrong", handleWarrantyStatusChangeError)
      toast.error("Something went wrong", { id: "errorWarrantyUpdate" })
    }
  }, [handleWarrantyStatusChangeData, handleWarrantyStatusChangeError])


  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "75px",
    },
    {
      name: "Name",
      selector: (row) => <h2>{row.name}</h2>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => row.product_code,
      sortable: true,
      width: "140px",
    },
    {
      name: "Product Name",
      selector: (row) => row.product_name,
      sortable: true,
      width: "140px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? <span className="text-green-600"> Active </span> : row.status == 3 ? <span className="text-red-600">Claimed</span> : row.status == "2" ? <span className="text-yellow-600">Pending </span> : <span className="text-gray-600">Invalid</span>,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Start date",
      selector: (row) => new Date(row.start_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => new Date(row.end_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.duration,
      sortable: true,
    },
    {
      name: "Platform Name",
      selector: (row) => row.platform,
      sortable: true,
    },
    {
      name: "Created By Name",
      selector: (row) => row.created_by_name,
      sortable: true,
      width: "140px",
    },
    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          onClick={() => handleShowWarranty(row)}
        >
          View
        </button>
      ),
      sortable: true,
      minWidth: "120px",
    },
    
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Warranty List
            {/* {userType.charAt(0).toUpperCase() + userType.slice(1)} List */}
            <LinksComponent />
          </div>

          <div className="p-3">
            {warrantyListCsvData && warrantyListCsvData?.body?.warrantyList && (
              <ExportCSV
                data={
                  warrantyListCsvData && warrantyListCsvData?.body?.warrantyList
                }
                columnsToExport={[
                  "name",
                  "phone",
                  "product_code",
                  "product_name",
                  "user_type",
                  "start_date",
                  "end_date",
                  "duration",
                ]}
              />
            )}
            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Phone</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Phone..."
                        required
                        value={request.phone}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, phone: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          fetchWarrantyListFunction();
                          fetchWarrantyListCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div></div>
              <DataTableComponent
                columns={columns}
                // title={""}
                data={myList}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex space-x-2 justify-end"></div>
              </DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"4xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="flex ">
                <div className="w-1/3  p-3 flex justify-center items-center">
                  {modalData?.images &&
                    modalData.images?.map((each, idx) => (
                      each && each.length > 0 &&
                      <img
                      key={idx}
                        class="w-52"
                        alt={each}
                        src={each}
                      />
                    ))}
                </div>

                <div className="w-2/3 bg-gray-50 p-4">
                  <div class="w-full">
                    <ul id="tabs" class="inline-flex pt-2 px-1 w-full border-b">
                      <li
                        class={`bg-gray-50  px-4 text-gray-800 font-medium text-lg py-2  
                       
                       ${selectedTab == "product" && " rounded-t border-t border-r border-l -mb-px"} 
                       `}
                      >
                        <a
                          onClick={() => setSelectedTab("product")}
                          id="default-tab"

                        >
                          {" "}
                          Product Details
                        </a>
                      </li>
                      <li
                        class={`bg-gray-50 px-4 text-gray-800 font-medium text-lg py-2   
                       
                       ${selectedTab == "warranty" && "  rounded-t border-t border-r border-l -mb-px"} 
                       `}
                      >
                        <a
                          onClick={() => setSelectedTab("warranty")}

                        >
                          Warranty Details
                        </a>
                      </li>
                    </ul>

                    <div id="tab-contents">
                      {selectedTab === "product" ? (
                        <div id="first" class="px-1 py-3">
                          <div class="grid grid-cols-2 gap-4">
                            <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                              Brand
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData?.brand_name}
                              </span>
                            </p>
                            <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              Category
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData?.category_name}
                              </span>
                            </p>
                            <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                              Product Code
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData?.product_code}
                              </span>
                            </p>

                            <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              Product Name
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData?.product_name}
                              </span>
                            </p>

                            <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                              Primary Warranty On
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {" "}
                                {modalData?.primary_warranty_on}
                              </span>
                            </p>
                            <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              Primary Warranty Duration
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {" "}
                                {modalData?.primary_warranty_period}
                              </span>
                            </p>
                            {modalData?.secondary_warranty_on && (
                              <>
                                <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                                  Secondary Warranty On
                                  <span className="flex text-black text-md font-weight-medium  gap-1">
                                    {modalData?.secondary_warranty_on}
                                  </span>
                                </p>
                                <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                                  Secondary Warranty Duration
                                  <span className="flex text-black text-md font-weight-medium  gap-1">
                                    {" "}
                                    {modalData?.secondary_warranty_period}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                          <p class="text-gray-400 pt-3 text-md block pb-2">
                            Description
                            <span className="flex text-black text-md font-weight-medium  gap-1">
                              {modalData?.description}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div id="second" class="px-1 py-3">
                          <div class="grid grid-cols-2 gap-4">
                            <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                              Name
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.name}
                              </span>
                            </p>

                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              {" "}
                              Contact
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.phone}
                              </span>
                            </p>

                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              App User ID
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.app_user_id}
                              </span>
                            </p>
                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              {" "}
                              User Type
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.user_type}
                              </span>
                            </p>
                            {modalData.city && (
                              <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                                City
                                <span className="flex text-black text-md font-weight-medium  gap-1">
                                  {modalData.city}
                                </span>
                              </p>
                            )}
                            {modalData.pincode && (
                              <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                                Pincode
                                <span className="flex text-black text-md font-weight-medium  gap-1">
                                  {modalData.pincode}
                                </span>
                              </p>
                            )}
                            {modalData.state && (
                              <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                                State
                                <span className="flex text-black text-md font-weight-medium  gap-1">
                                  {modalData.state}
                                </span>
                              </p>
                            )}

                            <p class="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              Primary Warranty Start :
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {/* {modalData.start_date.slice(0, 10)} */}
                                {new Date(
                                  modalData.start_date
                                ).toLocaleString()}
                              </span>
                            </p>
                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              Primary Warranty End :
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {/* {modalData.end_date.slice(0, 10)} */}
                                {new Date(modalData.end_date).toLocaleString()}
                              </span>
                            </p>





                            {modalData.secondary_warranty &&
                              modalData.secondary_warranty.start_date &&
                              modalData.secondary_warranty.end_date && (
                                <>
                                  <p class="text-gray-400 text-md  block mt-1 border-b border-gray-200 pb-2">
                                    Secondary Warranty Start :{" "}
                                    <span className="flex text-black text-md font-weight-medium  gap-1">
                                      {/* {modalData.secondary_warranty.start_date.slice(
                                      0,
                                      10
                                    )} */}
                                      {new Date(
                                        modalData.secondary_warranty?.start_date
                                      ).toLocaleString()}
                                    </span>
                                  </p>
                                  <p className="text-gray-400 text-md  block mt-1 border-b border-gray-200 pb-2">
                                    Secondary Warranty End :{" "}
                                    <span className=" flex text-black text-md font-weight-medium  gap-1">
                                      {/* {modalData.secondary_warranty?.end_date.slice(
                                      0,
                                      10
                                    )} */}
                                      {new Date(
                                        modalData.secondary_warranty?.end_date
                                      ).toLocaleString()}
                                    </span>
                                  </p>
                                </>
                              )}
                          </div>

                          <div className="flex mt-4 items-center gap-3">
                            <div className="">
                              {modalData.warranty_image.length > 0 &&
                                modalData.warranty_image.map((res) => (
                                  <span href="#" className="group  ">
                                    <div className="overflow-hidden  imgrs1 ">
                                      <img
                                        src={

                                          res
                                        }
                                        alt={res}
                                        className="max-w-full max-h-full "
                                        onClick={() =>
                                          setShowImageModal(

                                            res
                                          )
                                        }
                                      />
                                    </div>
                                  </span>
                                ))}

                              {showImageModal && (
                                <ImageModal
                                  show={showImageModal ? true : false}
                                  setShow={setShowImageModal}
                                  size="md"
                                  data={showImageModal}
                                >
                                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                      src={showImageModal}
                                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                  </div>
                                </ImageModal>
                              )}
                            </div>
                            {modalData?.warranty_pdf && (
                              <div className="">
                                <button
                                  type="button"
                                  className="inline-flex text-white justify-center rounded-md border border-transparent bg-blue-700 px-3 py-2 text-sm font-medium text-black-900 hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    onButtonDownloadClick(
                                      modalData?.warranty_pdf
                                    );
                                  }}
                                >
                                  <svg
                                    class="h-6 w-6 mr-2 text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />{" "}
                                    <polyline points="7 11 12 16 17 11" />{" "}
                                    <line x1="12" y1="4" x2="12" y2="16" />
                                  </svg>
                                  Download Warranty Pdf
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="secondary-info w-full ">
                        <h3 className="text-lg font-semibold mb-2">Additional Details</h3>
                        <div className="flex w-full flex-wrap space-x-2">
                          {modalData.secondary_data.map((field, index) => (
                            <div key={index} className="border p-2 m-2 rounded">
                              {
                                field.type === "file" ? <span href="#" className="group  ">
                                  <p><strong>{field.label}:</strong></p>
                                  <div className="overflow-hidden  imgrs1 ">
                                    <img
                                      src={field.value}
                                      alt={field.label}
                                      className="max-w-full max-h-full "
                                      onClick={() =>
                                        setShowImageModal(field.value)
                                      }
                                    />
                                  </div>
                                </span> :
                                  <p><strong>{field.label}:</strong> {field.value}</p>
                              }
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex justify-center mt-4 space-x-4">
                        {acceptWarrantyRequestPermission &&
                          modalData.status == "2" && (
                            <button
                              onClick={() => updateWarranty("1", modalData.id)}
                              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring focus:border-green-400"
                            >
                              Accept
                            </button>
                          )}

                        {rejectWarrantyRequestPermission &&
                          modalData.status == "2" && (
                            <button
                              onClick={() => updateWarranty("0", modalData.id)}
                              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring focus:border-red-400"
                            >
                              Reject
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex justify-center">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-black-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    setModalData(null);
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
              </div> */}
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default WarrantyList;
