import React from "react";

const LoaderComponent = () => {
    const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <div class="flex items-center justify-center h-full py-6">
    <div class="relative">
        <div class="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
        <div class="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8  animate-spin"
             style={{ borderColor: `${primaryColor["500"]}` }}
        >

        </div>
    </div>
</div>
  );
};

export default LoaderComponent;
