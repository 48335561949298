import React, { useEffect, useMemo, useState } from 'react'
import { getLoginUserData } from '../../../utils/getLoginUserData';
import { useAqualiteDistributorScansCountMutation, useAqualiteDistributorScansMutation } from '../../../features/tenant/Aqualite/aqualiteApi';
import { FaApple } from 'react-icons/fa';
import { FcAndroidOs } from 'react-icons/fc';
import { BsBrowserChrome } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { CircleLoaderComponent } from '../../../components/Loader/CircleLoaderComponent';
import DataTableComponent from '../../../components/Tenant/DataTableComponent/DataTableComponent';
import SelectComponent from '../../../components/Tenant/SelectComponent/SelectComponent';
import CustomExport from '../../../components/CustomExport/CustomExport';
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';


function objectsAreEqual(obj1, obj2) {
  console.log("ob1", obj1);
  console.log("ob2", obj2);
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}



const AppUsersScan = () => {
  const zoneId = getLoginUserData().zone_id;
  const [
    aqualiteDistributorScans,
    {
      data: aqualiteDistributorScansData,
      error: aqualiteDistributorScansError,
      isLoading: aqualiteDistributorScansIsLoading,
    },
  ] = useAqualiteDistributorScansMutation();
  const [
    aqualiteDistributorScansCount,
    {
      data: aqualiteDistributorScansCountData,
      error: aqualiteDistributorScansCountError,
      isLoading: aqualiteDistributorScansCountIsLoading,
    },
  ] = useAqualiteDistributorScansCountMutation();
  const [
    aqualiteDistributorScansCsv,
    { data: aqualiteDistributorScansCsvData, error: aqualiteDistributorScansCsvError },
  ] = useAqualiteDistributorScansMutation();

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState([{
    name: "All",
    id: null,
  }, ...appUserTypes]);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    user_type_id: userTypeId,
    name: "",
    limit: 10,
    offset: 0,
    product_code: "",
    batch_running_code: "",
    product_name: "",
    mobile: "",
    start_date: "",
    end_date: currentDate,
    zoneId,
  };

  const initialRequestCount = {
    user_type_id: userTypeId,
    name: "",
    product_code: "",
    batch_running_code: "",
    product_name: "",
    mobile: "",
    start_date: "",
    end_date: currentDate,
    zoneId,
  };
  const [request, setRequest] = useState(initialRequest);
  const [requestCount, setRequestCount] = useState(initialRequestCount);
  const [csvData, setCsvData] = useState(null);


  const clearFilters = () => {
    setRequest(initialRequest);
    setRequestCount(initialRequestCount);
    setUserTypesArray({
      name: "All",
      id: null,
    });
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element?.user_type?.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(() => {
    aqualiteDistributorScansCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: requestCount,
    });

  }, [requestCount])

  useEffect(() => {
    if (
      request.name === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.batch_running_code === "" &&
      request.mobile === "" &&
      request.start_date === "" &&
      request.end_date === currentDate
    ) {
      fetchAqualiteDistributorScanFunction();
      // fetchCsvPointsScanHistoryFunction();
    }


    let tempRequest = { ...request };
    delete tempRequest.limit;
    delete tempRequest.offset;

    if (!objectsAreEqual(tempRequest, requestCount)) {
      setRequestCount(tempRequest);
    }

  }, [request]);


  const fetchAqualiteDistributorScanFunction = () => {
    aqualiteDistributorScans({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCsvPointsScanHistoryFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    aqualiteDistributorScansCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zone_id: zoneId,
    });
  };

  useEffect(() => {
    if (aqualiteDistributorScansCsvData && aqualiteDistributorScansCsvData.body.data) {
      setCsvData(aqualiteDistributorScansCsvData.body.data);
    }

    if (aqualiteDistributorScansCsvError) {
      setCsvData(null);
    }
  }, [aqualiteDistributorScansCsvData, aqualiteDistributorScansCsvError]);

  useEffect(() => {
    if (aqualiteDistributorScansData) {
      console.log("success scan history", aqualiteDistributorScansData);
      setMyList(aqualiteDistributorScansData && aqualiteDistributorScansData.body && aqualiteDistributorScansData.body.data ? aqualiteDistributorScansData.body.data : []);
    }
  }, [aqualiteDistributorScansData, aqualiteDistributorScansError]);


  useEffect(() => {
    if (aqualiteDistributorScansCountData) {
      console.log("aqualiteDistributorScansCountData", aqualiteDistributorScansCountData);
      setTotalRows(aqualiteDistributorScansCountData && aqualiteDistributorScansCountData.body && aqualiteDistributorScansCountData.body.total ? aqualiteDistributorScansCountData.body.total : 0);
    }
  }, [aqualiteDistributorScansCountData, aqualiteDistributorScansCountError]);




  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },

    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform_id == 1 ? < FaApple className="m-auto" size={30} /> : row.platform_id == 2 ? <FcAndroidOs className="m-auto" size={30} /> : <BsBrowserChrome className="m-auto" size={30} />,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category Name",
      cell: (row) => row.category_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      width: "120px",
      sortable: true,
    },
    {
      name: "Method",
      cell: (row) => row.method,
      sortable: true,
    },
    // {
    //   name: "City",
    //   cell: (row) => row.city,
    //   sortable: true,
    // },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <div className="flex space-x-1 ">
            {row.lat && row.log ? (
              <div className="text-center">
                <Link
                  to={`https://maps.google.com/?q=${row.lat},${row.log}`}
                  target="_blank"
                >
                  <IoLocationSharp size={35} color="blue" className="m-auto" />
                  View Location
                </Link>{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
      width: "250px",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            App Users Scans
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data && (
            <ExportCSV
              data={
                pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data
              }
              columnsToExport={[
                "name",
                "mobile",
                "user_type",
                "points",
                "product_name",
                "product_code",
                "qr_id",
                "batch_running_code",
                "method",
              ]}
            />
          )} */}

            <div className="mb-2">
              <CustomExport
                fetchData={fetchAqualiteDistributorScanFunction}
                data={csvData}
                defaultColumnExport={[
                  "name",
                  "mobile",
                  "user_type",
                  "points",
                  "product_name",
                  "product_code",
                  "batch_running_code",
                  "method",
                ]}
                filename="aqualiteDistributorScan"
              />
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b border-gray-300 bg-gray-100 rounded-t-lg py-3 px-3 w-full">
                <div className="flex space-x-1 justify-evenly  w-full max-w-full">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-56 ">
                    <div className="flex-col slc_dvd topslt">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        User Type
                      </label>
                      <div className="relative searchin">
                        {appUsers && appUsers.length > 0 && (
                          <SelectComponent
                            className="capitalize"
                            //title={"User Types"}
                            selected={userTypesArray}
                            setSelected={setUserTypesArray}
                            dataArr={appUsers}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-56 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        P-Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-56 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        P-Code
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-56 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Batch R-Code
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Batch Running Code ..."
                        required
                        value={request.batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              batch_running_code: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>


                  <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.start_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.end_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-56 gap-2 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>

                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2 px-1.5"
                        onClick={() => {
                          fetchAqualiteDistributorScanFunction();
                          // fetchCsvPointsScanHistoryFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2 px-1.5"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {aqualiteDistributorScansIsLoading && <CircleLoaderComponent />}
                <DataTableComponent
                  columns={columns}
                  // title={""}
                  data={
                    myList
                  }
                  // loading={loading}
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  <div className="flex m-2 space-x-2 justify-end"></div>
                </DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppUsersScan