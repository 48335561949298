import React, { useEffect, useState } from 'react'
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';
// import SelectComponent from '../../../components/Tenant/SelectComponent/SelectComponent';
import { useGetAllStateMutation } from '../../../features/tenant/locations/locations.api';
import { useDashboardStatsForJqrMutation } from '../../../features/tenant/JqrSportsApi/jqrSportsApi';
import MultiSelectComponent from '../../../components/Tenant/MultiSelectComponent/MultiSelectComponent';
import toast from 'react-hot-toast';
import SelectComponent from '../../../components/Tenant/SelectComponent/SelectComponent';
import { useGetAllLocationsByZoneMutation, useGetAllZoneMutation } from '../../../features/tenant/zones/zonesApi';
import { findMatchingStates } from '../../../utils/findMatchingStates';
import LoaderComponent from '../../../components/Loader/LoaderComponent';
import { CircleLoaderComponent } from '../../../components/Loader/CircleLoaderComponent';

import { TbUserScan } from "react-icons/tb";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { TbCoins } from "react-icons/tb";
const defaultStartDate = `2024-09-01`;

// const date = new Date();
// const defaultStartDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`;


const JqrDashboard = () => {

    const currentDate = new Date().toISOString().split("T")[0];
    const [startDate, setStartDate] = useState(defaultStartDate);

    const [endDate, setEndDate] = useState(currentDate)

    const [selectedZone, setSelectedZone] = useState();
    const [allZones, setAllZones] = useState(null);

    const [allStates, setAllStates] = useState([]);
    const [originalStaes, setOriginalStaes] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);

    const [validStates, setValidStates] = useState([]);

    const [statsData, setStatsData] = useState(null);

    const [userStats, setUserStats] = useState(null)
    const [
        getAllState,
        {
            data: getAllStateData,
            isLoading: getAllStateIsLoading,
            error: getAllStateError,
        },
    ] = useGetAllStateMutation();


    const [
        fetchDashboardStats,
        {
            data: dashboardStatsData,
            isLoading: dashboardStatsIsLoading,
            error: dashboardStatsError,
        },
    ] = useDashboardStatsForJqrMutation();


    useEffect(() => {
        //   const hasViewPermission = hasPermission("productHierarchy");
        //   if (!hasViewPermission) {
        //     navigate(`/genefied/${getVendorName()}/`);
        //   }

        setStartDate(defaultStartDate);

        getAllState({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        });
    }, []);

    useEffect(() => {
        if (getAllStateData) {
            let states = getAllStateData.body.map((x) => {
                return {
                    id: x.id,
                    name: x.state,
                };
            });
            setOriginalStaes(states);
            const statesArr = [{ id: "0", name: "All" }, ...states]
            setAllStates(statesArr);
        }
    }, [getAllStateData, getAllStateError]);


    const fetchStatsData = () => {

        if (selectedStates && selectedStates.length > 0) {
            const finalStates = selectedStates.map((s) => s.name);
            // toast.loading("Fetching stats...", { id: "fetchingStats" });
            fetchDashboardStats({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: {
                    states: finalStates,
                    startDate: startDate,
                    endDate: endDate,
                    userTypeId: 2
                }
            })
        } else {
            if (selectedZone && selectedZone.id != -2) {
                toast.error("Please select some states", { id: "missingStates" })
                return;
            }
            // console.log("Selected zone", selectedZone)
            // toast.loading("Fetching stats...", { id: "fetchingStats" });
            fetchDashboardStats({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: {
                    states: [],
                    startDate: startDate,
                    endDate: endDate,
                    userTypeId: 2
                }
            })
        }
    }


    useEffect(() => {
        if (selectedStates) {
            // console.log("selectedStates", selectedStates);
            if (selectedStates.length > 0) {
                let found = false;
                selectedStates.forEach(state => {
                    if (state.id == "0")
                        found = true;
                })
                if (found) {
                    setSelectedStates(originalStaes);
                }
            }
        }
    }, [selectedStates])



    useEffect(() => {

        if (dashboardStatsData && dashboardStatsData.body) {
            toast.dismiss();
            // console.log("dashboardStatsData", dashboardStatsData)


            const groupedUserData = dashboardStatsData.body?.users.reduce((acc, user) => {
                if (user.status !== "0") {
                    const { user_type } = user;
                    if (!acc[user_type]) {
                        acc[user_type] = [];
                    }
                    acc[user_type].push(user);
                }
                return acc;
            }, {});
            // console.log("groupedUserData", groupedUserData);
            setUserStats(groupedUserData)
            setStatsData(dashboardStatsData.body);
        }

        if (dashboardStatsError) {
            toast.dismiss();
            console.error("dashboardStatsError", dashboardStatsError)
            toast.error("Something went wrong", { id: "dashboardStatsError" })
        }
    }, [dashboardStatsData, dashboardStatsError])


    const clearFilters = () => {
        setSelectedStates([]);
        setStartDate(defaultStartDate); 
        setEndDate(currentDate);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "1":
                return "Active";
            case "2":
                return "Inactive";
            default:
                return "Unknown";
        }
    };

    const [
        getAllZone,
        {
            data: getAllZoneData,
            isLoading: getAllZoneIsLoading,
            error: getAllZoneError,
            isError: getAllZoneIsError
        },
    ] = useGetAllZoneMutation();

    useEffect(() => {
        getAllZone({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        });
        const allStateData = JSON.parse(sessionStorage.getItem("zones-states"));
        // console.log("allStateData", allStateData);
        if (allStateData == null) {
            getAllState({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            });
        } else {
            setAllStates(allStateData);
        }
    }, []);

    useEffect(() => {
        if (getAllZoneData && getAllZoneData.body) {
            // console.log("getAllZoneData", getAllZoneData.body);
            const arr = [];
            // arr.push({ id: 0, name: "No Zone Alloted" });
            arr.push({ id: -2, name: "All Zones" });
            getAllZoneData.body.forEach((element) => {
                arr.push({ id: element.id, name: element.zone_name });
            });
            setSelectedZone(arr[0]);
            setAllZones(arr);
        }
        if (getAllZoneError) {
            toast.error("Error fetching zones", { id: "getAllZoneError" })
            // console.log("getAllZoneError", getAllZoneError);
        }
    }, [getAllZoneData, getAllZoneError]);

    const [
        getAllLocationsZone,
        {
            data: getAllLocationZoneData,
            isLoading: getAllLocationZoneIsLoading,
            error: getAllLocationZoneError,
        },
    ] = useGetAllLocationsByZoneMutation();

    useEffect(() => {
        if (allZones && allZones.length > 0) {
            // console.log("selected zone: ", selectedZone);

            if (!selectedZone || selectedZone.id == -1) {
                setSelectedZone(allZones[0]);
            }

            getAllLocationsZone({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                zone_id: selectedZone.id,
            });
        }
    }, [selectedZone, allZones]);

    useEffect(() => {
        if (allZones && allZones.length > 0 && getAllLocationZoneData && getAllLocationZoneData.body) {
            // console.log("allZones: ", allZones, getAllLocationZoneData?.body);

            const zoneIds = [];
            if (!selectedZone || selectedZone.id == -1) {
                setSelectedZone(allZones[0]);
            }
            setSelectedStates([]);
            setValidStates([]);
            // zoneIds.push(selectedZone.id);

            if (selectedZone.id == -2) {
                allZones.forEach((zone) => {
                    zoneIds.push(zone.id);
                })
            } else {
                zoneIds.push(selectedZone.id);
            }

            // console.log("selected zones" , JSON.stringify(zoneIds));


            // console.log("inside", allStates, getAllLocationZoneData.body);
            const matchingData = findMatchingStates(allStates, getAllLocationZoneData.body);
            // console.log("matchingData", matchingData);
            setValidStates(matchingData);

            fetchStatsData();



        }
    }, [selectedZone, allZones, allStates, getAllLocationZoneData]);


    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b flex justify-between items-center bg-white border-gray-300 pl-6 py-1 shadow-sm text-xl font-bold">
                        <span>
                            Stats
                            <LinksComponent />
                        </span>
                    </div>
                    {getAllZoneIsLoading ? <CircleLoaderComponent />
                        :
                        getAllZoneIsError ? "Something went wrong" :
                            <>
                            <div className="p-3">
                                <div className="w-full bg-white rounded-t-lg">
                                    <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                            <div className="w-full max-w-5xl flex justify-start items-end space-y-4 md:space-y-0 md:space-x-4">
                                        {/* <div className="w-2/4">
                                <MultiSelectComponent
                                    title={"Select States"}
                                    placeholder={"Select States ..."}
                                    selected={selectedStates}
                                    setSelected={setSelectedStates}
                                    dataArr={allStates ? allStates : []}
                                />
                            </div> */}
                                        <div className="w-full flex justify-start space-x-3 ">
                                            <div className=" w-1/2">
                                                {allZones && allZones.length > 0 && (
                                                    <div className="w-full">
                                                        <SelectComponent
                                                            className="w-full"
                                                            title={"Select Zone"}
                                                            selected={selectedZone}
                                                            setSelected={setSelectedZone}
                                                            dataArr={allZones}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className=" w-1/2">
                                                {validStates && validStates.length > 0 && (
                                                    <div className="w-full">
                                                        <MultiSelectComponent
                                                            className="w-full"
                                                            title={"Select State"}
                                                            selected={selectedStates}
                                                            setSelected={setSelectedStates}
                                                            dataArr={validStates}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                        <div className="w-2/4 flex space-y-4 md:space-y-0 md:space-x-4">
                                            <div className="w-1/2">
                                                <label
                                                    htmlFor="start-date"
                                                    className="block text-sm font-medium text-gray-700 mb-2"
                                                >
                                                    Start Date
                                                </label>
                                                <input
                                                    id="start-date"
                                                    type="date"
                                                    className="w-full px-3 py-2 shadow-md text-sm border rounded-lg text-gray-900 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                                    placeholder="Start Date"
                                                    value={startDate}
                                                    onChange={(e) => {
                                                        setStartDate(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <label
                                                    htmlFor="end-date"
                                                    className="block text-sm font-medium text-gray-700 mb-2"
                                                >
                                                    End Date
                                                </label>
                                                <input
                                                    id="end-date"
                                                    type="date"
                                                    className="w-full px-3 py-2 shadow-md text-sm border rounded-lg text-gray-900 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                                    placeholder="End Date"
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        setEndDate(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <button
                                            className="mt-0.5 text-white bg-blue-500 hover:bg-blue-600  font-medium rounded-lg text-sm px-3 py-2 text-center "
                                            onClick={() => fetchStatsData()}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="mt-0.5 text-white bg-red-400 hover:bg-red-600  font-medium rounded-lg text-sm px-3 py-2 text-center "
                                            onClick={() => clearFilters()}
                                        >
                                            Clear
                                        </button>
                                    </div></div>

                                <div className="p-4">

                                    {
                                        dashboardStatsIsLoading ? <LoaderComponent /> :
                                            <>
                                                <div className="flex justify-start space-x-5 items-start ">

                                                    {userStats && Object.keys(userStats).length > 0 && Object.entries(userStats).map(([userType, users] , index) => (
                                                        <div
                                                            key={`${userType}${index}`}
                                                            className="bg-white shadow-sm min-w-60 min-h-52 rounded-lg p-4 border border-gray-200 mb-4"
                                                        >
                                                            <p className="font-bold text-xl text-gray-800 capitalize bg-gray-50 p-1">
                                                                {userType}
                                                            </p>
                                                            {users.map((user, index) => (
                                                                <div key={index} className="mt-2">
                                                                    <p className="text-gray-600">
                                                                        Count : <b>{user.user_count}</b>
                                                                    </p>
                                                                    <p className="text-gray-600 mt-1">
                                                                        <span>Status :</span>{" "}
                                                                        <span
                                                                            className={`px-2 mx-1 py-1 rounded-md text-md ${user.status === "1"
                                                                                ? "bg-green-100 text-green-600"
                                                                                : "bg-red-100 text-red-600"
                                                                                }`}
                                                                        >
                                                                            {getStatusLabel(user.status)}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}


                                                </div>

                                                <div className="mt-4 border-t-2 border-gray-50 py-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                                                    {statsData && statsData?.scanning.map((data, index) => (
                                                        <>
                                                            <div
                                                                // key={index}
                                                                key={"distinct_user_count"}
                                                                className="bg-red-100 text-center shadow-sm max-w-72 rounded-lg p-3 "
                                                            > <span className='flex justify-center m-auto text-5xl pb-1'><TbUserScan /> </span>
                                                                <h2 className="text-2xl font-bold text-gray-800 capitalize ">
                                                               
                                                                {data.distinct_user_count}
                                                               
                                                                </h2>
                                                                <p className="text-base text-gray-600 ">
                                                                Qr Scan Retailers
                                                                </p>

                                                            </div>

                                                            <div
                                                                // key={index}
                                                                key={"distinct_qr_count"}
                                                                className="bg-blue-100 text-center shadow-sm max-w-72 rounded-lg p-3 "
                                                            ><span className='flex justify-center m-auto text-5xl pb-1'><MdOutlineDocumentScanner /> </span>
                                                                <h2 className="text-2xl font-bold text-gray-800 capitalize ">
                                                                {data.distinct_qr_count} 
                                                                </h2>
                                                                <p className="text-base text-gray-600 ">
                                                                Total Scanned Pairs

                                                                </p>

                                                            </div>

                                                            <div
                                                                // key={index}
                                                                key={"total_mrp"}
                                                                className="bg-purple-100 text-center shadow-sm max-w-72 rounded-lg p-3 "
                                                            ><span className='flex justify-center m-auto text-5xl pb-1'><TbCoins /> </span>
                                                                <h2 className="text-2xl font-bold text-gray-800 capitalize ">
                                                                {data.total_mrp}
                                                                </h2>
                                                                <p className="text-base text-gray-600">
                                                                Total Mrp Value 

                                                                </p>

                                                            </div>
                                                        </>


                                                    ))}
                                                </div>
                                            </>
                                    }
                                </div>
                                </div></div>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default JqrDashboard;