
import { rootApi } from "../../api/rootApi";

export const aqualiteApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    aqualiteDistributorScans: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/aqualite/distributor-scan`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    aqualiteDistributorScansCount: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/aqualite/distributor-count`,
            headers: {
                Authorization: "Bearer " + params.token,
                slug: params.tenant_id,
              },
              body: params.body,
          };
        },
      })
  }),
});

export const { useAqualiteDistributorScansCountMutation , useAqualiteDistributorScansMutation} = aqualiteApi;
