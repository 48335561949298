

  const getField = (data, fieldPath) => {
    return fieldPath.split('.').reduce((current, key) => {
      if (!current) return ""; // If the current value is undefined or null, return an empty string
  
      // Check if the key has an array index (e.g., gift[0])
      const arrayMatch = key.match(/^(\w+)\[(\d+)\]$/);
      if (arrayMatch) {
        const [_, arrayKey, index] = arrayMatch;
        return current[arrayKey] ? current[arrayKey][parseInt(index, 10)] : "";
      }
  
      // Check for "length-1" to get the last element of an array
      if (key.endsWith("[length-1]")) {
        const arrayKey = key.replace("[length-1]", "");
        return current[arrayKey] && current[arrayKey].length > 0
          ? current[arrayKey][current[arrayKey].length - 1]
          : "";
      }
  
      // Default case: access the property directly
      return current[key];
    }, data);
  };
  
  export const transformToJSON = (data, columns) => {
    return data.map(entry => {
      const newItem = {};
      columns.forEach(col => {
        newItem[col.header] = getField(entry, col.field);
      });
      return newItem;
    });
  };
  
  // Transform the data
//   const transformedData = transformToJSON(data, columns);
//   console.log(JSON.stringify(transformedData, null, 2));
  

