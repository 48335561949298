import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useFetchAllQrCodeWithoutGenerationMutation, useFetchQrByBatchRunningCodeMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import ViewQr from "../../../components/Tenant/ViewQR/ViewQr";
import { getVendorName } from "../../../utils/getVendorName";


const SearchByBatchRunningCode = () => {
  const navigate = useNavigate();
  const initialRequest = {
    batch_running_code: "",

  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [tableColumns, setTableColumns] = useState([]);

  const columns = [
   
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "165px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "140px",
    },
    {
      name: "Qr Code",
      cell: (row) => row.unique_code,
      sortable: true,
      width: "130px",
    },
    {
      name: "Scratch Code",
      cell: (row) => row.scratch_code,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-sm font-bold bg-${row.parent_code === null
            ? "gray"
            : row.parent_code === "false"
              ? "red"
              : "blue"
            }-400 text-white animate-pulse duration-1000`}
        >
          {row.parent_code === null
            ? "Child"
            : row.parent_code === "false"
              ? "Parent"
              : "Child"}
        </span>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "MRP",
      cell: (row) => row.mrp,
      sortable: true,
      width: "100px",
    },
    {
      name: "Qr Status",
      cell: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-sm  font-bold bg-${row.qr_status == "2" ? "red" : "blue"
            }-700 text-white animate-pulse duration-0`}
        >
          {row.qr_status == "2" ? "Scanned" : "Not Scanned"}
        </span>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white animate-blinking-button shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-red-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            onClick={() =>
              navigate(`/genefied/${getVendorName()}/tracking`, { state: row })
            }
          >
            Track
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleShowQr(row)}
          >
            GVerify Qr
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleWebGenefiedQr(row)}
          >
            Web Qr
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleBatchShowQr(row)}
          >
            View Qr
          </button>
        </div>
      ),
      sortable: true,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    qrListDataFun,
    {
      data: qrListData,
      isLoading: qrListDataIsLoading,
      error: qrListDataError,
    },
  ] = useFetchQrByBatchRunningCodeMutation();



  const fetchAllQrCodeFunction = () => {
    qrListDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  useEffect(() => {
    const c = [];
    if (JSON.parse(sessionStorage.getItem("vendorData")).tenant_name === "jqr") {
      c.push({
        name: "Invoice Number",
        cell: (row) => row?.details?.invoice_no,
        sortable: true,
        width: "180px",
      });
      c.push({
        name: "Distributor Code",
        cell: (row) => row?.details?.user_id,
        sortable: true,
        width: "150px",
      });
    }else {
      c.push({
        name: "Mapped Mobile",
        cell: (row) => row?.details?.mobile,
        sortable: true,
        width: "140px",
      });
    }
    setTableColumns([...c , ...columns]);
    fetchAllQrCodeFunction();

  }, [request]);

  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState(null);

  const handleShowQr = (data) => {
    let obj = {
      link: data.unique_code.includes("gverify.me") 
        ? data.unique_code
        : `http://gverify.me/?${data.unique_code}`, 
      ...data,
    };
  
    setQrData(obj);
    setShowQr(true);
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const handleBatchShowQr = (data) => {
    

    let obj = {
      // link: `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`,
      link: `${data.unique_code}`,
      ...data,
    };

    // Ultimatrue Specific Changes
    if(JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 17){
      obj.link =  `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`
    }


    setQrData(obj);
    setShowQr(true);
  };


  const handleWebGenefiedQr = (data) => {
    

    let obj = {
      link: `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`,
      // link: `${data.unique_code}`,
      ...data,
    };


    setQrData(obj);
    setShowQr(true);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Qrs List
            <LinksComponent />
          </div>

          <div className="p-3">

            <div className="flex gap-2">


            </div>
            <div className=" bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Batch Running Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Batch Running Code..."
                        required
                        value={request.batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              batch_running_code: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2  w-56 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all "
                        onClick={() => {
                          fetchAllQrCodeFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
                columns={tableColumns}
                data={qrListData && qrListData.body}
                loading={loading}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showQr && <ViewQr data={qrData} show={showQr} setShow={setShowQr} />}
      </div>
    </>
  );
};

export default SearchByBatchRunningCode;
