import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useGetSavedCustomerDataMutation } from "../../../features/tenant/Ultimatrue/ultimatrueApi";
import toast from "react-hot-toast";


export default function CustomerDataList() {
  const navigate = useNavigate();
  const location = useLocation();


  const initialRequest = {
    email: "",
    limit: 20,
    offset: 0,
    mobile: "",
    name: "",
    status: "",
    startDate: "",
    endDate: "",
  };

  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [
    fetchAllSavedCustomerData,
    { data: savedCustomerListData, error: savedCustomerListError },
  ] = useGetSavedCustomerDataMutation();

  useEffect(() => {
    fetchAllSavedCustomerData({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);

  useEffect(() => {
    if (savedCustomerListData && savedCustomerListData.body) {
      console.log("savedCustomerListData", savedCustomerListData);
      setMyList(savedCustomerListData?.body.data)
      setTotalRows(savedCustomerListData?.body.total)

    }

    if (savedCustomerListError) {
      console.log("savedCustomerListError", savedCustomerListError);
      toast.error("Something went wrong", { id: "savedCustomerListError" })
    }
  }, [savedCustomerListData, savedCustomerListError]);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    // {
    //   name: "Customer Name",
    //   selector: (row) => row.type,
    //   // sortable: true,
    // },
    {
      name: "User Name  ",
      selector: (row) => row.name,
    },
    {
      name: "User Phone",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Customer Name  ",
      selector: (row) => row.customer_name,
    },
    {
      name: "Customer Phone",
      selector: (row) => row.customer_phone,
      sortable: true,
    },

    {
      name: "Customer Address  ",
      selector: (row) => row.customer_address,
    },
    {
      name: "Customer Email  ",
      selector: (row) => row.customer_email,
    },
    {
      name: "Created At",
      selector: (row) => { new Date(row.created_at).toLocaleTimeString() },
      sortable: true,
    },
    // {
    //   name: "",
    //   cell: (r) => (
    //     <button
    //       className="btn btn-outline-primary btn-sm"
    //       onClick={() => {
    //         setModalData(r);
    //         setShowModal(true);
    //       }}
    //     >
    //       View
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];




  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setRequest({ ...request, limit: newPerPage });
  };

  //   const handleDelete = (id) => {
  //     toast(
  //       (t) => (
  //         <span>
  //           <h6>Are you sure you want to delete this ?</h6>
  //           <div className="mt-3 d-flex">
  //             <button
  //               className="btn btn-outline-danger btn-sm ml-auto mr-1"
  //               onClick={() => {
  //                 toast.dismiss();
  //                 deleteEmailTemplate({
  //                   token: sessionStorage.getItem("access_token"),
  //                   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //                   id : id
  //                 })
  //               }}
  //             >
  //               Yes
  //             </button>
  //             <button
  //               className="btn btn-outline-primary btn-sm mr-auto ml-1"
  //               onClick={() => {
  //                 toast.dismiss(t.id);
  //               }}
  //             >
  //               No
  //             </button>
  //           </div>
  //         </span>
  //       ),
  //       {
  //         id: "deleteWorkflow",
  //       },
  //     );
  //     return;
  //   };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Customer Data
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={myList}
                totalRows={
                  totalRows
                }
              // handlePerRowsChange={handlePerRowsChange}
              // handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
