import React, { useEffect, useMemo, useState } from "react";

import toast from "react-hot-toast";
import { useGetCheckoutListDataMutation } from "../../../features/supplyBeam/supplyBeamData/checkoutListAPI";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";

function objectsAreEqual(obj1, obj2) {
    console.log("ob1", obj1);
    console.log("ob2", obj2);
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
            return false;
        }
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}


const CheckoutList = () => {
    const zoneId = getLoginUserData().zone_id;

    const [
        fetchCheckoutList,
        {
            data: checkoutListData,
            error: checkoutListError,
            isLoading: checkoutListIsLoading,
        },
    ] = useGetCheckoutListDataMutation();


    const [myList, setMyList] = useState([])
    const [totalRows, setTotalRows] = useState(0);

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [prevPage, setPrevPage] = useState(0);
    const currentDate = new Date().toISOString().split("T")[0];
    const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    const [appUsers, setAppUsers] = useState(appUserTypes);
    const [userTypesArray, setUserTypesArray] = useState({
        name: "All",
        id: null,
    });
    const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
    const initialRequest = {
        user_type_id: userTypeId,
        name: "",
        limit: 10,
        offset: 0,
        product_code: "",
        batch_running_code: "",
        product_name: "",
        mobile: "",
        start_date: "",
        end_date: currentDate,
        zoneId,
    };

    const initialRequestCount = {
        user_type_id: userTypeId,
        name: "",
        product_code: "",
        batch_running_code: "",
        product_name: "",
        mobile: "",
        start_date: "",
        end_date: currentDate,
        zoneId,
    };
    const [request, setRequest] = useState(initialRequest);
    const [requestCount, setRequestCount] = useState(initialRequestCount);

    const clearFilters = () => {
        setRequest(initialRequest);
        setRequestCount(initialRequestCount);
    };

    useMemo(() => {
        for (let index = 0; index < appUsers.length; index++) {
            const element = appUsers[index];
            if (
                element.user_type.toLocaleLowerCase() ===
                userTypesArray?.name?.toLocaleLowerCase()
            ) {
                setUserTypeId(element.user_type_id);
                setRequest((prevRequest) => ({
                    ...prevRequest,
                    user_type_id: +element.user_type_id,
                }));
                break;
            }
        }
    }, [userTypesArray]);


    useEffect(() => {
        if (
            request.name === "" &&
            request.product_code === "" &&
            request.product_name === "" &&
            request.batch_running_code === "" &&
            request.mobile === "" &&
            request.start_date === "" &&
            request.end_date === currentDate
        ) {
            fetchCheckoutListFunction();
            // fetchCsvPointsScanHistoryFunction();
        }


        let tempRequest = { ...request };
        delete tempRequest.limit;
        delete tempRequest.offset;

        if (!objectsAreEqual(tempRequest, requestCount)) {
            setRequestCount(tempRequest);
        }

    }, [request]);

    // useEffect(() => {
    //   if (limit != 10 || offset != 0) fetchCheckoutListFunction();
    //   // fetchCsvPointsScanHistoryFunction();
    // }, [limit, offset]);

    const fetchCheckoutListFunction = () => {
        fetchCheckoutList({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            body: request,
        });
    };


    const [csvData, setCsvData] = useState(null);

    useEffect(() => {
        if (checkoutListData) {
            console.log("success checkout list", checkoutListData);
            setMyList(checkoutListData && checkoutListData.data ? checkoutListData?.data : []);
        }


        if (checkoutListError) {
            toast.dismiss();
            toast.error("Something went wrong", { id: "checkoutListError" })
        }
    }, [checkoutListData, checkoutListError]);


    const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        setRequest({ ...request, offset: newOffset });
        setPrevPage(pageNumber - 1);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRequest({ ...request, limit: newPerPage });
        setLimit(newPerPage);
    };


    const columns = [
        {
            name: "SNo",
            cell: (row, idx) => offset + Number(idx + 1),
            sortable: true,
            width: "80px",
        },

        {
            name: "Ref",
            cell: (row) => row.ref,
            sortable: true,
            width: "220px",
        },
        {
            name: "User Type",
            cell: (row) => row.user_type,
            sortable: true,
            width: "120px",
        },
        {
            name: "Out Name",
            cell: (row) => row.out_name,
            sortable: true,
            width: "220px",
        },
        {
            name: "Out Code",
            cell: (row) => row.out_code,
            sortable: true,
            width: "220px",
        },
        {
            name: "Total Count",
            cell: (row) => row.total_count,
            sortable: true,
            width: "150px",

        },
        {
            name: "Created At",
            cell: (row) => new Date(row.created_at).toLocaleString(),
            width: "120px",
            sortable: true,
        },

    ];

    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                        Checkout List
                        <LinksComponent />
                    </div>

                    <div className="p-3">
                        {/* {pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data && (
              <ExportCSV
                data={
                  pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data
                }
                columnsToExport={[
                  "name",
                  "mobile",
                  "user_type",
                  "points",
                  "product_name",
                  "product_code",
                  "qr_id",
                  "batch_running_code",
                  "method",
                ]}
              />
            )} */}


                        <div className=" w-full bg-white shadow rounded-lg">
                            <div className="">
                                <DataTableComponent
                                    columns={columns}
                                    // title={""}
                                    data={
                                        myList
                                    }
                                    // loading={loading}
                                    totalRows={
                                        totalRows
                                    }
                                    handlePerRowsChange={handlePerRowsChange}
                                    handlePageChange={handlePageChange}
                                >
                                    <div className="flex m-2 space-x-2 justify-end"></div>
                                </DataTableComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutList;
