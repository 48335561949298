import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// export const rootApi = createApi({
//     reducerPath: 'apiTushar',
//     tagTypes: [],
//     baseQuery: fetchBaseQuery({
//         baseUrl: 'http://localhost:5000/api/admin',
//     }),
//     endpoints: (builder) => ({}),
// });

export const rootApi = createApi({
    reducerPath: 'apiTushar',
    tagTypes: [],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    }),
    endpoints: (builder) => ({}),
});


export const rootApiSupplyBeam = createApi({
    reducerPath: 'apiTushar',
    tagTypes: [],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SUPPLY_BEAM_BASE_URL}`,
    }),
    endpoints: (builder) => ({}),
});